<template>
    <div>
        <b-skeleton-table v-if="loading" :columns="2" :rows="5"></b-skeleton-table>
        <b-container v-else :key="ticks">

            <b-row class="mb-1">
                <b-col class="font-weight-bold">Rubrik</b-col>
                <b-col>
                    <b-input v-model="entry.title" placeholder="Ange rubrik"></b-input>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col class="font-weight-bold">Text</b-col>
                <b-col>
                    <b-form-textarea v-model="entry.message" placeholder="Skriv din nyhet..."></b-form-textarea>
                </b-col>
            </b-row>

            <b-row class="mb-1">
                <b-col class="font-weight-bold">Leverans</b-col>
                <b-col>
                    <b-form-checkbox v-model="entry.email" :value="true" :unchecked-value="false">
                        Epost
                    </b-form-checkbox>
                     <b-form-checkbox v-model="entry.sms" :value="true" :unchecked-value="false">
                        SMS
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <b-row class="mt-2" v-if="haserror">
                <b-col>
                    <Alert variant="danger" v-for="(  err, index  ) in terrors" :key="index" >{{err}}</Alert>
                </b-col>
            </b-row>


            <hr />

            <b-row class="mb-1 mt-2">
                <b-col class="text-center">
                    <b-button class="mx-1" @click="Cancel()" variant="secondary">Avbryt</b-button>
                    <b-button class="mx-1" @click="AddNewsEntry()" variant="primary">Spara</b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
export default {
    Name: "AddEditNews",
    props: {
        "entry": Object
    },
    components: {
        Alert
    },
    data()
    {
        return {
            terrors: [],
            ticks: 1,
        }
    },
    computed: {
        loading()
        {
            return this.$store.getters[ "adminpost/commonloading" ];
        },
        haserror()
        {
            return this.terrors.length > 0;
        }
    },
    methods: {
        AddNewsEntry()
        {
            this.$store.dispatch("adminpost/AddNewsEntry", this.entry).then((response) =>
            {
                if (response.status == "ok")
                {
                    this.$emit("saved");
                } else
                {
                    this.terrors = response.errors;
                }
            });
        },
        Cancel()
        {
            this.$emit("cancel");
        }
    },
};
</script>
